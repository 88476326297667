import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { spinner } from '@modules/spinner/spinner';
import { SnackBarService } from '@services/snack-bar/snack-bar.service';
import { PostRequest } from '@type/requests.type';
import { ListWithPagination } from '@type/response.type';
import { TableData } from '@type/table-data.type';
import { UserType, UserStrategyType, UsersList } from '@type/user.type';
import { joinUrls } from '@utils/urls';
import { BehaviorSubject, EMPTY, Observable, Subject, catchError, lastValueFrom, map, tap } from 'rxjs';

@Injectable()
export class UsersService {
  updateUsersTrigger = new Subject<void>();

  viewType = new BehaviorSubject<UserStrategyType>('create');

  userTitle: Record<UserStrategyType, string> = {
    create: 'settings_page.users.invite_member',
    edit: 'settings_page.users.edit_user',
  };

  private http = inject(HttpClient);

  private snack = inject(SnackBarService);

  private baseUsersRequest: PostRequest = {
    page: 1,
    per_page: environment.perPage,
    sorting: '',
    q: {
      first_name_or_last_name_or_email_cont: '',
    },
  };

  getUsersList(opts: any = this.baseUsersRequest): Observable<TableData<UsersList>> {
    return this.http
      .post<ListWithPagination<{ users: UsersList }>>(joinUrls(environment.urls.users, environment.urls.index), opts)
      .pipe(
        catchError(this.showErrorMessage),
        map(({ meta, users }) => ({ length: meta.total_count, data: users })),
      );
  }

  @spinner({ selectorById: 'settings-spinner-container' })
  createUser(opts: any): Observable<UserType> {
    return this.http.post<UserType>(environment.urls.users, opts).pipe(
      catchError(this.showErrorMessage),
      tap((res) => {
        this.createInvitationUrl(res);
      }),
    );
  }

  @spinner({ selectorById: 'settings-spinner-container' })
  updateUser(user: any): Observable<UserType> {
    return this.http.put<UserType>(joinUrls(environment.urls.users, String(user['id'])), user).pipe(
      catchError(this.showErrorMessage),
      tap((res) => {
        if (res.status.status === 'invited') {
          this.createInvitationUrl(res);
        }
        this.updateUsersTrigger.next();
      }),
    );
  }

  invateUser(user: { email: string }): Observable<{ invitation_url: string }> {
    return this.http.post<{ invitation_url: string }>(environment.urls.invitation, user).pipe(
      catchError(this.showErrorMessage),
      tap(() => {
        this.updateUsersTrigger.next();
      }),
    );
  }

  async createInvitationUrl(user: UserType) {
    await lastValueFrom(
      this.invateUser({
        email: user.email,
      }),
    );
  }

  @spinner({ selectorById: 'settings-spinner-container' })
  suspendUser(user: UserType, method: string): Observable<UserType> {
    return this.http.post<UserType>(joinUrls(environment.urls.users, String(user['id']), method), '').pipe(
      catchError(this.showErrorMessage),
      tap((res) => {
        if (method === 'unblock' && !user.last_sign_in_at) {
          this.createInvitationUrl(res);
        }
        this.updateUsersTrigger.next();
      }),
    );
  }

  @spinner({ selectorById: 'settings-spinner-container' })
  resetPassword(user: UserType): Observable<UserType> {
    const { users, invite } = environment.urls;
    return this.http.post<UserType>(joinUrls(users, String(user['id']), invite), '').pipe(
      catchError(this.showErrorMessage),
      tap(() => {
        this.updateUsersTrigger.next();
      }),
    );
  }

  createUsersRequest(opts: Partial<PostRequest>): PostRequest {
    return Object.assign(this.baseUsersRequest, opts);
  }

  private showErrorMessage = (err: any): Observable<never> => {
    const { error } = err;
    const message = error.email ? `Email ${error.email}` : error.error;
    this.snack.show('error', { text: message });
    return EMPTY;
  };
}
